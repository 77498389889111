import React, { useEffect } from 'react';

import { SEO } from '../components';
import { Background, Product } from '../lib/types';
import { useAppDispatch } from '../redux/hooks';
import { changeBackground, changeCurrentLogo } from '../redux/reducers/ui';
import { PageLayout, Spei } from '../widgets';

import '../assets/fonts/jakarta.css';
import { Box } from '@chakra-ui/react';

const SpeiPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changeBackground(Background.Secondary));
    dispatch(changeCurrentLogo({ product: Product.Arteria, isWhite: true }));
  }, [dispatch]);

  return (
    <>
      <SEO
        title="Arteria Spei"
        description="Legal alternative to Fintech as a Service in Mexico"
        image="../assets/images/logos/bridge.svg"
      />
      <PageLayout isOverflowActive>
        <Spei />
      </PageLayout>
    </>
  );
};

export default SpeiPage;
